import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Zoom } from "react-awesome-reveal";

import { handleChatButtonClick } from "utils/chat-button-click";

import { SectionContainer } from "components/common/section-container.component";
import { Subtitle, Title } from "components/common/typography.component";
import { Button } from "components/common/button.component";
import { BlurElement } from "components/common/blur-element.component";
import { ButtonType, SectionId, TextSize } from "enums";
import { useLocation } from "react-router-dom";
import { MY_ACCOUNT_PATH } from "router/constants";
import { twMerge } from "tailwind-merge";

export const ContactUs: FC = () => {
    const { t } = useTranslation();

    const location = useLocation();

    const pathname = location.pathname.toLowerCase().replace(/[^\w]/g, "");
    const isPortalPage = pathname === MY_ACCOUNT_PATH.replace(/[^\w]/g, "");

    return (
        <footer
            id={SectionId.CONTACT_US}
            aria-label={t("common.contactUs")}
            className={twMerge("py-20 bg-dark relative overflow-hidden", isPortalPage && "hidden")}
        >
            <BlurElement className="opacity-10" />

            <SectionContainer>
                <Zoom triggerOnce>
                    <Title size={TextSize.SMALL} className="mb-3">
                        {t("common.contactUs")}
                    </Title>
                    <Subtitle className="mb-5">{t("contactUs.feelFree")}</Subtitle>

                    <Button
                        buttonType={ButtonType.FILLED}
                        className="w-fit sm:w-full"
                        onClick={handleChatButtonClick}
                    >
                        {t("common.contactUs")}
                    </Button>
                </Zoom>
            </SectionContainer>
        </footer>
    );
};
